<template>
  <div id="document" class="solutions-area section-width ptb-100">
    <div class="container">
      <div class="section-title">
        <h2>{{ $t("navbar.menu_2_6") }}</h2>
      </div>
      <div class="row justify-content-center mt-5">
        <div class="category-navigate">
          <ul>
            <a
              href="#"
              @click.prevent="getFileList(item.tag_name)"
              v-for="(item, index) in tagList"
              :key="index"
            >
              <li
                :class="[
                  'btn btn-primary btn-lg',
                  { active: item.tag_name == tagSelected },
                ]"
                @click.prevent="selectTag(item.tag_name, true)"
              >
                {{ item.tag_name }}
              </li>
            </a>
          </ul>
        </div>
      </div>
      <div class="container">
        <table v-if="isMobile" class="table table-striped">
          <tbody>
            <tr v-for="(item, index) in items" :key="index" class="file-item">
              <div class="file-item-info">
                <div class="file-item-head">
                  <p :title="item.更新時間">{{ item.更新時間 }}</p>
                  <p :title="item.檔案大小">{{ item.檔案大小 }}</p>
                </div>
                <p :title="item.檔案名稱">{{ item.檔案名稱 }}</p>
              </div>
              <a
                :href="item.download"
                :title="item.檔案名稱"
                target="_blank"
                size="sm"
                class="btn btn-primary btn mr-1"
              >
                {{ $t("table.th_4.btn") }}
              </a>
            </tr>
          </tbody>
        </table>
        <table v-else class="table table-striped">
          <thead>
            <tr>
              <th scope="col">{{ $t("table.th_1") }}</th>
              <th scope="col" class="filename">{{ $t("table.th_2") }}</th>
              <th scope="col">{{ $t("table.th_3") }}</th>
              <th scope="col">{{ $t("table.th_4.title") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in items" :key="index">
              <th scope="row" :title="item.更新時間">{{ item.更新時間 }}</th>
              <td class="filename" :title="item.檔案名稱">{{ item.檔案名稱 }}</td>
              <td :title="item.檔案大小">{{ item.檔案大小 }}</td>
              <td>
                <a
                  :href="item.download"
                  :title="item.檔案名稱"
                  target="_blank"
                  size="sm"
                  class="btn btn-primary btn mr-1"
                >
                  {{ $t("table.th_4.btn") }}
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div v-if="!isShowNextPage" class="more-button">
      <div class="read-more">
        <button class="btn btn-warning" @click="getNextDocumentList()">
          {{ $t("pageBtn") }}<i class="fas fa-chevron-right ml-2"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import DateFormat from "dateformat";
export default {
  name: "DocumentBody",
  components: {},
  props: {
    title: String,
  },
  data() {
    return {
      tagList: [],
      fields: [
        "更新時間",
        "檔案名稱",
        "檔案大小",
        {
          key: "download",
          label: "檔案下載",
        },
      ],
      items: [],
      tagSelected: "",
      isShowNextPage: false,
      page: 1,
      apiUrl: `${process.env.VUE_APP_API_URL}/resources/`,
      language_id: this.$i18n.locale,
      isMobile: window.innerWidth < 992,
    };
  },
  async mounted() {
    await this.getTagList();
    if (this.tagList.length != 0) {
      this.selectTag(this.tagList[0].tag_name);
    }
    this.getFileList();
    window.addEventListener("resize", this.checkIsMobile);
  },
  methods: {
    selectTag(tagName, refresh = false) {
      if (refresh) {
        this.page = 1;
      }
      this.tagSelected = tagName;
      this.getFileList();
    },
    async getTagList() {
      console.log(this.language_id);
      let lang = "";
      if (this.language_id == "en-us") {
        lang = ",en-us";
      }
      let api = `${this.apiUrl}/tag_list`;
      let postParams = {
        resource_id: 14,
        page: 1,
        limit: 0,
        /* 設定為 0 則顯示全部*/
        order_by: [],
        filters: {
          tag_group: this.title + lang,
        },
      };
      let postConfig = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      await this.axios.post(api, postParams, postConfig).then((response) => {
        let data = response["data"]["list"];
        for (let i = 0; i < data.length; i++) {
          this.tagList.push(data[i]);
        }
      });
    },
    async getFileList() {
      let api = `${this.apiUrl}/file_list`;
      let postParams = {
        resource_id: 14,
        page: this.page,
        limit: "6",
        order_by: [
          {
            field: "upload_date",
            method: "DESC",
          },
        ],
        filters: {
          tags: [[this.tagSelected]],
        },
        image_width: 2048,
      };
      let postConfig = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      await this.axios.post(api, postParams, postConfig).then((response) => {
        let data = response["data"]["list"];
        let pageNumber = response["data"]["count"]["all"];
        this.showPageBtnCheck(pageNumber);
        if (this.page == 1) {
          this.items = [];
        }
        for (let i = 0; i < data.length; i++) {
          this.items.push({
            isActive: true,
            更新時間: DateFormat(
              new Date(Date.parse(data[i]["upload_date"].replace(/-/g, "/"))),
              "yyyy-mm-dd"
            ),
            檔案名稱: data[i]["file_name"],
            檔案大小: (data[i]["file_size"] / 1024 ** 2).toFixed(1) + " MB",
            download: data[i]["path"],
          });
        }
      });
    },
    getNextDocumentList() {
      this.page = this.page + 1;
      this.getFileList();
    },
    showPageBtnCheck(pageNumber) {
      this.isShowNextPage = false;
      if (parseInt(pageNumber) / 6 <= this.page) {
        this.isShowNextPage = true;
      }
    },
    checkIsMobile() {
      this.isMobile = window.innerWidth < 992;
    },
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.checkIsMobile);
  },
};
</script>

<style lang="scss">
.is-show-next-page {
  display: none;
}

#document .container {
  max-width: 1100px;
}

.more-button {
  display: flex;
  justify-content: center;
  
  .read-more i {
    top: 0;
  }
}

@media (width < 992px) {
  .table {
    display: block;

    tbody {
      display: block;
      width: 100%;
    }

    .file-item {
      display: flex;
      flex-direction: column;
      align-items: self-start;
      gap: 12px;
      padding: 16px 20px;

      .file-item-info {
        width: 100%;

        p {
          margin: 0;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          line-clamp: 2;
          box-orient: vertical;
          word-break: break-all;
        }

        .file-item-head {
          display: flex;
          justify-content: space-between;
        }
      }
    }
  }
}

@media (width >= 992px) {
  .table {
    white-space: nowrap;
    table-layout: fixed;

    td,
    th {
      overflow: hidden;
      text-overflow: ellipsis;
      width: 120px;
      max-width: 100%;

      &.filename {
        width: auto;
      }
    }
  }
}
</style>
